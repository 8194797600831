import { createRouter, createWebHistory } from 'vue-router'
import { loadLayoutMiddleware } from "./middleware/layoutMiddleware.js";
// import { isSubscribed, isLoggedIn } from "@/router/middleware/guards.js";
import middlewarePipeline from "../router/middlewarePipeline.js";


const routes = [
  {
    path: '/business',
    name: 'ResearcherLanding',
    component: () => import('../views/ResearcherLanding.vue'),
    meta: {
      type: "researcher_unauthorised",
      layout: "LandingPage"
    },
  },
  {
    path: '/',
    name: 'CreatorLanding',
    component: () => import('../views/CreatorLanding.vue'),
    meta: {
      type: "creator_unauthorised",
      layout: "LandingPage"
    },
  },
  {
    path: '/get-in-touch',
    name: 'ContactUs',
    component: () => import('../views/ContactUs.vue'),
    meta: {
      type: "researcher_unauthorised",
      layout: "LandingPage"
    },
  },
  // {
  //   path: '/pricing',
  //   name: 'PublicPricing',
  //   component: () => import('../views/PublicPricing.vue'),
  //   meta: {
  //     type: "creator_unauthorised",
  //     layout: "LandingPage"
  //   },
  // },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import('../views/PrivacyPolicy.vue'),
    meta: {
      type: "public",
      layout: "LandingPage"
    },
  },
  {
    path: '/terms-and-conditions',
    name: 'Terms&Condition',
    component: () => import('../views/T&C.vue'),
    meta: {
      type: "public",
      layout: "LandingPage"
    },
  },

  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginPage.vue'),
    children: [
      {
        path: '',
        name: "creatorLogin",
        component: () => import('../components/CreatorLogin.vue'),
        meta: {
          type: "creatorLogin"
        },
      },
      {
        path: '/register',
        name: "creatorRegister",
        component: () => import('../components/CreatorRegister.vue'),
        meta: {
          type: "creatorLogin"
        },
      },
      {
        path: '/r/login',
        name: "researcherLogin",
        component: () => import('../components/ResearcherLogin.vue'),
        meta: {
          type: "researcherLogin"
        },
      },
      {
        path: '/r/register',
        name: "researcherRegister",
        redirect: '/get-in-touch',
        // component: () => import('../components/ResearcherRegister.vue'),
        meta: {
          type: "researcherLogin"
        },
      },

    ],
  },
  {
    path: '/r/confirm',
    name: 'confirmOTP',
    component: () => import('../views/ConfirmOTP.vue'),
    meta: {
      type: "researcherLogin"
    },
  },
  {
    path: '/confirm',
    name: 'creatorConfirmOTP',
    component: () => import('../views/ConfirmOTP.vue'),
    meta: {
      type: "creatorLogin"
    },
  },
  {
    path: '/getting-started',
    name: 'GettingStarted',
    component: () => import('../views/GettingStarted.vue'),
    meta: {
      type: "researcher_onboarding"
    },
  },
  {
    path: '/researcher/pricing-plans',
    name: 'ResearcherPricing',
    component: () => import('../views/ResearcherPricing.vue'),
    meta: {
      type: "researcher_onboarding"
    },
  },
  {
    path: '/researcher/subscription/success',
    name: 'ResearcherPaymentSuccess',
    component: () => import('../views/ResearcherPaymentSuccess.vue'),
    meta: {
      type: "researcher_unauthorised"
    },
  },
  {
    path: '/research/payment/success',
    name: 'ResearchPaymentSuccess',
    component: () => import('../views/ResearcherPaymentSuccess.vue'),
    meta: {
      type: "researcher_unauthorised"
    },
  },
  {
    path: '/research/payment/failed',
    name: 'ResearchPaymentFailed',
    component: () => import('../views/ResearcherPaymentFailed.vue'),
    meta: {
      type: "researcher_unauthorised"
    },
  },
  {
    path: '/researcher/failed',
    name: 'ResearcherPaymentFailed',
    component: () => import('../views/ResearcherPaymentFailed.vue'),
    meta: {
      type: "researcher_unauthorised"
    },
  },
  {
    path: '/r/dashboard',
    name: 'ResearcherDashboard',
    component: () => import('../views/ResearcherDashBoard.vue'),
    children: [
      {
        path: '',
        name: 'AllResearches',
        components: {
          default: () => import('../components/Researcher/MyResearches.vue'),
          RightSection: () => import('../components/Researcher/CreateSurveyInterview.vue'),
        }
      },
    ],
    meta: {
      layout: 'ResearcherLayout',
      type: "researcher"
    },
  },
  {
    path: '/r/:research_id/applications',
    name: 'ResearchApplications',
    component: () => import('../components/Researcher/ResearchApplications.vue'),
    meta: {
      layout: 'ResearcherLayout',
      type: "researcher"
    },
  },
  {
    path: '/r/settings',
    name: 'ResearcherSettings',
    component: () => import('../views/Researcher/Settings.vue'),
    meta: {
      layout: 'ResearcherLayout',
      type: 'researcher'
    },
    children: [
      {
        path: '',
        name: 'OrganizationDetails',
        component: () => import('../components/Researcher/OrganizationDetails.vue'),
      },
      {
        path: '',
        name: 'ResearcherDetails',
        component: () => import('../components/Researcher/ResearcherDetails.vue'),
      },
      {
        path: '',
        name: 'OrganizationTeam',
        component: () => import('../components/Researcher/OrganizationTeam.vue'),
      },
      {
        path: '',
        name: 'AccountSettings',
        component: () => import('../components/Researcher/AccountSettings.vue'),
      }
    ]
  },
  {
    path: '/researches',
    name: 'MyResearches',
    component: () => import('../views/Researcher/MyResearch.vue'),
    meta: {
      layout: 'ResearcherLayout',
      type: 'researcher'
    },
  },
  {
    path: '/create-study',
    name: 'CreateStudy',
    component: () => import('../views/Researcher/CreateStudy.vue'),
    children: [
      // {
      //   path: 'survey',
      //   name: 'CreateSurvey',
      //   component: () => import('../views/Researcher/CreateSurvey.vue'),
      //   meta: {
      //     type: "researcher_unauthorised",

      //   }
      // },
      {
        path: '',
        name: 'SelectStudyType',
        component: () => import('../components/Researcher/SelectStudyType.vue'),
      },
      {
        path: '',
        name: 'CreateInterviewOverview',
        component: () => import('../components/Researcher/CreateInterviewOverview.vue'),
        meta: {
          layout: 'StudyLayout',
        },
      },
      {
        path: '',
        name: 'CreateInterviewSchdule',
        component: () => import('../components/Researcher/CreateInterviewSchdule.vue'),
        meta: {
          layout: 'StudyLayout',
        },
      },
      {
        path: '',
        name: 'CreateSurveyOverview',
        component: () => import('../components/Researcher/CreateSurveyOverview.vue'),
        meta: {
          layout: 'StudyLayout',
        },
      },
      {
        path: '',
        name: 'CreateSurveyGenre',
        component: () => import('../components/Researcher/CreateSurveyGenre.vue'),
        meta: {
          layout: 'StudyLayout',
        },
      },
      {
        path: '',
        name: 'CreateSurveyContentFormat',
        component: () => import('../components/Researcher/CreateSurveyContentFormat.vue'),
        meta: {
          layout: 'StudyLayout',
        },
      },
      {
        path: '',
        name: 'CreateSurveyPlatform',
        component: () => import('../components/Researcher/CreateSurveyPlatform.vue'),
        meta: {
          layout: 'StudyLayout',
        },
      },
      {
        path: '',
        name: 'CreateSurveyScreenerQuestions',
        component: () => import('../components/Researcher/CreateSurveyScreenerQuestions.vue'),
        meta: {
          layout: 'StudyLayout',
        },
      },
      {
        path: '',
        name: 'CreateSurveyTargetCreators',
        component: () => import('../components/Researcher/CreateSurveyTargetCreators.vue'),
        meta: {
          layout: 'StudyLayout',
        },
      },
    ],
    meta: {
      layout: 'ResearcherLayout',
      type: 'researcher'
    },
  },
  {
    path: '/calendar/connected',
    name: 'GoogleCalendarRedirect',
    component: () => import('../views/Researcher/GoogleCalendarRedirection.vue'),
    meta: {
      type: 'public'
    }
  },
  {
    path: "/creator/getting-started",
    name: "CreatorOnboarding",
    component: () => import('../views/Creator/CreatorOnboarding.vue'),
    meta: {
      layout: 'TitleLayout',
      type: 'creator'
    },
    children: [
      {
        path: '',
        name: "CreatorInfo",
        component: () => import('../components/Creator/CreatorInfo.vue')
      },
      {
        path: '',
        name: "CreatorPlatform",
        component: () => import('../components/Creator/CreatorPlatform.vue')
      },
      {
        path: '',
        name: "CreatorContentFormat",
        component: () => import('../components/Creator/CreatorContentFormat.vue')
      },
      {
        path: '',
        name: "CreatorGenre",
        component: () => import('../components/Creator/CreatorGenre.vue')
      },
      {
        path: '',
        name: "ConnectSocial",
        component: () => import('../components/Creator/ConnectSocial.vue')
      },
    ],
  },
  {
    path: '/welcome',
    name: "CreatorWelcomePage",
    component: () => import('../components/Creator/WelcomePage.vue'),
    meta: {
      type: 'creator'
    },
  },
  {
    path: '/home',
    name: 'CreatorHome',
    component: () => import('../views/Creator/CreatorHome.vue'),
    meta: {
      layout: 'CreatorLayout',
      type: 'creator'
    },
    children: [
      {
        path: 'apply/:survey_id',
        name: "ApplyForResearch",
        component: () => import('../components/Creator/ApplyForResearch.vue')
      },
      {
        path: 'apply/:survey_id',
        name: "InstantScheduleInterview",
        component: () => import('../components/Creator/SelectAppointment.vue')
      },
    ]
  },
  {
    path: '/study/:research_id',
    name: 'CreatorStudy',
    component: () => import('../views/Creator/CreatorStudy.vue'),
    meta: {
      layout: 'CreatorLayout',
      type: 'creator_unauthorised'
    },
    children: [
      {
        path: 'apply/:survey_id',
        name: "ApplyForResearchFromStudy",
        component: () => import('../components/Creator/ApplyForResearch.vue')
      },
      {
        path: 'apply',
        name: "StudyInstantScheduleInterview",
        component: () => import('../components/Creator/SelectAppointment.vue')
      },
    ]
  },
  {
    path: '/epsos/study/:research_id',
    name: 'EpsosCreatorStudy',
    component: () => import('../views/Creator/EpsosSurveyResult.vue'),
    meta: {
      layout: 'CreatorLayout',
      type: 'creator_unauthorised'
    },
  },
  {
    path: '/upcoming-studies',
    name: 'UpcomingResearch',
    component: () => import('../views/Creator/UpcomingResearch.vue'),
    meta: {
      layout: 'CreatorLayout',
      type: 'creator'
    },
    children: [
      {
        path: 'schedule/:survey_id',
        name: "ScheduleInterview",
        component: () => import('../components/Creator/SelectAppointment.vue')
      },
    ]
  },
  {
    path: '/earnings',
    name: 'CreatorEarnings',
    component: () => import('../views/Creator/Earnings.vue'),
    meta: {
      layout: 'CreatorLayout',
      type: 'creator'
    },
  },
  {
    path: '/settings',
    name: "CreatorSettings",
    component: () => import('../views/Creator/Settings.vue'),
    meta: {
      layout: 'CreatorLayout',
      type: 'creator'
    },
    children: [
      {
        path: 'profile',
        name: 'EditCreatorProfile',
        component: () => import('../components/Creator/EditCreatorProfile.vue')
      },
      {
        path: 'info',
        name: 'EditOtherCreatorInfo',
        component: () => import('../components/Creator/EditOtherCreatorProfile.vue')
      },
      {
        path: 'socials',
        name: 'EditConnectedAccounts',
        component: () => import('../components/Creator/EditConnectedAccounts.vue')
      }
    ]
  },
  // Admin Routes
  {
    path: '/admin/dashboard',
    name: 'AdminDashboard',
    component: () => import('../views/Admin/Dashboard.vue'),
    meta: {
      type: 'admin',
      layout: 'AdminLayout'
    }
  },
  {
    path: '/admin/creators',
    name: 'AdminCreators',
    component: () => import('../views/Admin/Creators.vue'),
    meta: {
      type: 'admin',
      layout: 'AdminLayout'
    }
  },
  {
    path: '/admin/creator/:creator_id/reffered',
    name: 'ReferredCreators',
    component: () => import('../views/Admin/ReferredCreators.vue'),
    meta: {
      type: 'admin',
      layout: 'AdminLayout'
    }
  },
  {
    path: '/admin/researchers',
    name: 'AdminResearchers',
    component: () => import('../views/Admin/Researchers.vue'),
    meta: {
      type: 'admin',
      layout: 'AdminLayout'
    }
  },
  {
    path: '/admin/studies',
    name: 'AdminStudies',
    component: () => import('../views/Admin/Studies.vue'),
    meta: {
      type: 'admin',
      layout: 'AdminLayout'
    }
  },
  {
    path: '/admin/study/:research_id',
    name: 'AdminApplicants',
    component: () => import('../views/Admin/Applicants.vue'),
    meta: {
      type: 'admin',
      layout: 'AdminLayout'
    }
  },
  {
    path: '/admin/mail-list',
    name: 'AdminMailingList',
    component: () => import('../views/Admin/AdminMailingList.vue'),
    meta: {
      type: 'admin',
      layout: 'AdminLayout'
    }
  },
  {
    path: '/admin/ads',
    name: 'AllAds',
    component: () => import('../views/Admin/AdminAds.vue'),
    meta: {
      type: 'admin',
      layout: 'AdminLayout'
    }
  },
  {
    path: '/admin/emails',
    name: 'AdminSendEmails',
    component: () => import('../views/Admin/AdminSendEmails.vue'),
    meta: {
      type: 'admin',
      layout: 'AdminLayout'
    }
  },
  {
    path: '/admin/miscellaneous',
    name: 'AdminMiscellaneous',
    component: () => import('../views/Admin/AdminMiscellaneous.vue'),
    meta: {
      type: 'admin',
      layout: 'AdminLayout'
    }
  },
  {
    path: '/admin/debit-requests',
    name: 'AdminPayoutRequest',
    component: () => import('../views/Admin/PayoutRequest.vue'),
    meta: {
      type: 'admin',
      layout: 'AdminLayout'
    }
  },
  {
    path: '/admin/balance-available',
    name: 'AdminCreatorsWithBalance',
    component: () => import('../views/Admin/BalanceAvailable.vue'),
    meta: {
      type: 'admin',
      layout: 'AdminLayout'
    }
  },
  {
    path: '/admin/add-creator',
    name: 'AdminCreateCreator',
    component: () => import('../views/Admin/CreateCreator.vue'),
    meta: {
      type: 'admin',
      layout: 'AdminLayout'
    }
  },
  {
    path: '/admin/edit-creator',
    name: 'AdminEditCreator',
    component: () => import('../views/Admin/CreateCreator.vue'),
    meta: {
      type: 'admin',
      layout: 'AdminLayout'
    }
  },
  {
    path: '/admin/organisations',
    name: 'AdminOrganisations',
    component: () => import('../views/Admin/Organisations.vue'),
    meta: {
      type: 'admin',
      layout: 'AdminLayout'
    }
  },
  {
    path: '/admin/organisations/create',
    name: 'AdminOrganisationsCreate',
    component: () => import('../views/Admin/OrganisationsCreateEdit.vue'),
    meta: {
      type: 'admin',
      layout: 'AdminLayout'
    }
  },
  {
    path: '/admin/organisations/edit',
    name: 'AdminOrganisationsEdit',
    component: () => import('../views/Admin/OrganisationsCreateEdit.vue'),
    meta: {
      type: 'admin',
      layout: 'AdminLayout'
    }
  },
  {
    path: '/admin/researcher/add',
    name: 'AdminAddResearcher',
    component: () => import('../views/Admin/OrganisationsAddReseacher.vue'),
    meta: {
      type: 'admin',
      layout: 'AdminLayout'
    }
  },
  {
    path: '/admin/create-study',
    name: 'AdminCreateStudy',
    component: () => import('../views/Researcher/CreateStudy.vue'),
    children: [
      {
        path: '',
        name: 'AdminSelectStudyType',
        component: () => import('../components/Researcher/SelectStudyType.vue'),
      },
      {
        path: '',
        name: 'AdminCreateInterviewOverview',
        component: () => import('../components/Researcher/CreateInterviewOverview.vue'),
        meta: {
          layout: 'AdminStudyLayout',
        },
      },
      {
        path: '',
        name: 'AdminCreateInterviewSchdule',
        component: () => import('../components/Researcher/CreateInterviewSchdule.vue'),
        meta: {
          layout: 'AdminStudyLayout',
        },
      },
      {
        path: '',
        name: 'AdminCreateSurveyOverview',
        component: () => import('../components/Researcher/CreateSurveyOverview.vue'),
        meta: {
          layout: 'AdminStudyLayout',
        },
      },
      {
        path: '',
        name: 'AdminCreateSurveyGenre',
        component: () => import('../components/Researcher/CreateSurveyGenre.vue'),
        meta: {
          layout: 'AdminStudyLayout',
        },
      },
      {
        path: '',
        name: 'AdminCreateSurveyContentFormat',
        component: () => import('../components/Researcher/CreateSurveyContentFormat.vue'),
        meta: {
          layout: 'AdminStudyLayout',
        },
      },
      {
        path: '',
        name: 'AdminCreateSurveyPlatform',
        component: () => import('../components/Researcher/CreateSurveyPlatform.vue'),
        meta: {
          layout: 'AdminStudyLayout',
        },
      },
      {
        path: '',
        name: 'AdminCreateSurveyScreenerQuestions',
        component: () => import('../components/Researcher/CreateSurveyScreenerQuestions.vue'),
        meta: {
          layout: 'AdminStudyLayout',
        },
      },
      {
        path: '',
        name: 'AdminCreateSurveyTargetCreators',
        component: () => import('../components/Researcher/CreateSurveyTargetCreators.vue'),
        meta: {
          layout: 'AdminStudyLayout',
        },
      },
    ],
    meta: {
      layout: 'AdminLayout',
      type: 'admin'
    },
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
  routes
})

// Before each route changing the loadLayoutMiddleware middleware is executing.
// router.beforeEach((to, from, next) => {
//   if (!to.meta.middleware) {
//     return next()
//   }
//   const middleware = to.meta.middleware

//   const context = {
//     to,
//     from,
//     next,
//     store
//   }
//   return middleware[0]({
//     ...context,
//     next: middlewarePipeline(context, middleware, 1)
//   })
// })

router.beforeEach(loadLayoutMiddleware);

export default router