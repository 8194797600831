import axios from "axios";
import mixpanel from 'mixpanel-browser';
// import { useUserStore, useSurveyStore, useCreateSurveyStore } from "../stores/store.js";
let userStore;
let surveyStore
let createSurveyStore
let creatorOnboardingStore;
let upcomingSurveyStore;
let earningsStore
let countryStore

const getStore = async () => {
	let { useUserStore, useSurveyStore, useCreateSurveyStore, useCreatorOnboarding, useCreatorDashboardSurveysStore, useEarningsStore, useCountryStore } = await import(`../stores/store.js`)
	userStore = useUserStore();
	surveyStore = useSurveyStore();
	createSurveyStore = useCreateSurveyStore();
	creatorOnboardingStore = useCreatorOnboarding()
	upcomingSurveyStore = useCreatorDashboardSurveysStore()
	earningsStore = useEarningsStore()
	countryStore = useCountryStore()
}
getStore()
console.log("Process:", process.env.NODE_ENV);
let baseURL = import.meta.env.VITE_API_URL

export async function updateUser() {
	try {
		let response = await axios({
			method: "POST",
			url: baseURL + "api/researcher/verify_otp",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
		});
		if (await response.data.message) {
			return true;
		}
	} catch (err) {
		console.log("Error updating user info", err);
		alert("Invalid OTP")
		return false;
	}
}

export async function researcherSignUP(name, email) {
	let data = { name, email }
	try {
		let response = await axios({
			method: "POST",
			url: baseURL + "api/researcher/signup",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: data,
		});
		if (response.data) {
			await sendOTP(email);
		}
	} catch (err) {
		console.log("Error updating user info", err);
	}
}
export async function sendOTP(email) {
	try {
		let response = await axios({
			method: "POST",
			url: baseURL + "api/researcher/generate_otp",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: { email },
		});
		if (response.data.message) {
			userStore.$patch({
				user: { email: email },
			});
			console.log(response.data.message);
			return true;
		}
	} catch (err) {
		console.log("Error updating user info", err);
		alert("You have not yet registerd. Register now!")
		return false;
	}
}

const setCookie = (name, value, days) => {
	var expires = "";
	if (days) {
		var date = new Date();
		date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
		expires = "; expires=" + date.toUTCString();
	}
	document.cookie = name + "=" + (value || "") + expires + "; path=/";
}
export async function confirmOTP(otp, email) {
	let data = {
		email: email,
		code: otp,
	};
	try {
		let response = await axios({
			method: "POST",
			url: baseURL + "api/researcher/verify_otp",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: data,
		});
		if (response.data) {
			userStore.saveUserInfo(response.data)
			mixpanel.track('researcher_login')
			return response.data;
		}
	} catch (err) {
		console.log("Error updating user info", err);
	}
}

export async function getResearchersInfo() {
	try {
		let response = await axios({
			method: "GET",
			url: baseURL + "api/researcher",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
		});
		if (response.data) {
			userStore.saveUserInfo(response.data)
			return response.data;
		}
	} catch (err) {
		console.log("Error updating info", err);
	}
}

export async function createOrganization(formData) {
	try {
		let response = await axios({
			method: "POST",
			url: baseURL + "api/researcher/organisation",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: formData,
		});
		if (response.data) {
			return response.data;
		}
	} catch (err) {
		console.log("Error updating info", err);
	}
}

export async function getResearcherSurveys() {
	surveyStore.loadingSurveys(true)
	try {
		let response = await axios({
			method: "GET",
			url: baseURL + "api/researcher/researches",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
		});
		if (response.data) {
			surveyStore.saveSurveys(response.data);
		}
		surveyStore.loadingSurveys(false)
		return response.data;
	} catch (err) {
		console.log("Error updating info", err);
		surveyStore.loadingSurveys(false)
	}
}
export async function getFilterdResearcherSurveys(filter) {
	let params = new URLSearchParams()
	if (filter) {
		params.append("status", filter)
	}
	surveyStore.loadingSurveys(true)
	try {
		let response = await axios({
			method: "GET",
			url: baseURL + "api/researcher/researches",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			params: params
		});
		if (response.data) {
			surveyStore.saveFilterdSurvey(response.data);
		}
		surveyStore.loadingSurveys(false)
		return response.data;
	} catch (err) {
		console.log("Error updating info", err);
		surveyStore.loadingSurveys(false)
	}
}

export async function getUploadUrl(file) {
	try {
		let response = await axios({
			method: "POST",
			url: baseURL + "api/researcher/get_upload_url",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: { name: file.name },
		});
		console.log(response);
		return response.data;
	} catch (err) {
	}
}

export async function getCountries() {
	if (countryStore && Object.keys(countryStore.countries).length > 0) {
		return countryStore.countries
	}
	try {
		let response = await axios({
			method: "GET",
			url: baseURL + "countries",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
		});
		if (response.data.current) {
			let countries_obj = response.data
			delete countries_obj['countries'][response.data.current.code]
			countryStore.saveCountries(countries_obj)
			return countries_obj;
		}
	} catch (err) {
		console.log("Error updating info", err);
	}
}

export async function createSurvey() {
	let formData = {
		creator_content_formats: createSurveyStore.surveyDetails.creator_content_formats,
		creator_genres: createSurveyStore.surveyDetails.creator_genres,
		creator_incentive_amount: createSurveyStore.surveyDetails.creator_incentive_amount,
		creator_platforms: createSurveyStore.surveyDetails.creator_platforms,
		deadline: createSurveyStore.surveyDetails.deadline,
		description: createSurveyStore.surveyDetails.description,
		doc_object_key: createSurveyStore.surveyDetails.doc_object_key,
		duration: createSurveyStore.surveyDetails.duration,
		is_content_format_deal_breaker: createSurveyStore.surveyDetails.is_content_format_deal_breaker,
		is_genre_deal_breaker: createSurveyStore.surveyDetails.is_genre_deal_breaker,
		is_platform_deal_breaker: createSurveyStore.surveyDetails.is_platform_deal_breaker,
		name: createSurveyStore.surveyDetails.name,
		other_information: createSurveyStore.surveyDetails.other_information,
		screener_questions: createSurveyStore.surveyDetails.screener_questions,
		signature_required: createSurveyStore.surveyDetails.signature_required,
		survey_link: createSurveyStore.surveyDetails.survey_link,
		target_audience_sizes: createSurveyStore.surveyDetails.target_audience_sizes,
		target_countries: createSurveyStore.surveyDetails.target_countries,
		target_creator_count: createSurveyStore.surveyDetails.target_creator_count,
	}
	try {
		let response = await axios({
			method: "POST",
			url: baseURL + "api/researcher/survey",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: formData
		});
		console.log(response);
		if (response.data) {
			if (response.data.payment_link) {
				window.open(response.data.payment_link, '_self')
				return response.data;
				// await checkoutResearch(response.data.id)
				// return response.data;
			}
			return response.data;
		}
	} catch (err) {
		console.log("Error updating info", err);
	}
}

export async function updateSurvey() {
	let formData = {
		id: createSurveyStore.surveyDetails.id,
		creator_content_formats: createSurveyStore.surveyDetails.creator_content_formats,
		creator_genres: createSurveyStore.surveyDetails.creator_genres,
		creator_incentive_amount: createSurveyStore.surveyDetails.creator_incentive_amount,
		creator_platforms: createSurveyStore.surveyDetails.creator_platforms,
		deadline: createSurveyStore.surveyDetails.deadline,
		description: createSurveyStore.surveyDetails.description,
		doc_object_key: createSurveyStore.surveyDetails.doc_object_key,
		duration: createSurveyStore.surveyDetails.duration,
		is_content_format_deal_breaker: createSurveyStore.surveyDetails.is_content_format_deal_breaker,
		is_genre_deal_breaker: createSurveyStore.surveyDetails.is_genre_deal_breaker,
		is_platform_deal_breaker: createSurveyStore.surveyDetails.is_platform_deal_breaker,
		name: createSurveyStore.surveyDetails.name,
		other_information: createSurveyStore.surveyDetails.other_information,
		screener_questions: createSurveyStore.surveyDetails.screener_questions,
		signature_required: createSurveyStore.surveyDetails.signature_required,
		survey_link: createSurveyStore.surveyDetails.survey_link,
		target_audience_sizes: createSurveyStore.surveyDetails.target_audience_sizes,
		target_countries: createSurveyStore.surveyDetails.target_countries,
		target_creator_count: createSurveyStore.surveyDetails.target_creator_count,
	}
	try {
		let response = await axios({
			method: "PUT",
			url: baseURL + "api/researcher/survey",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: formData
		});
		console.log(response);
		if (response.data) {
			// if (!createSurveyStore.surveyDetails.payment_complete) {
			// 	await checkoutResearch(response.data.id)
			// }
			if (response.data.payment_link) {
				window.open(response.data.payment_link, '_self')
				return response.data;
			}
			return response.data;
		}
	} catch (err) {
		console.log("Error updating info", err);
	}
}

export async function creteInterview() {
	let formData = {
		creator_content_formats: createSurveyStore.surveyDetails.creator_content_formats,
		creator_genres: createSurveyStore.surveyDetails.creator_genres,
		creator_incentive_amount: createSurveyStore.surveyDetails.creator_incentive_amount,
		creator_platforms: createSurveyStore.surveyDetails.creator_platforms,
		description: createSurveyStore.surveyDetails.description,
		doc_object_key: createSurveyStore.surveyDetails.doc_object_key,
		duration: createSurveyStore.surveyDetails.duration,
		is_content_format_deal_breaker: createSurveyStore.surveyDetails.is_content_format_deal_breaker,
		is_genre_deal_breaker: createSurveyStore.surveyDetails.is_genre_deal_breaker,
		is_platform_deal_breaker: createSurveyStore.surveyDetails.is_platform_deal_breaker,
		name: createSurveyStore.surveyDetails.name,
		other_information: createSurveyStore.surveyDetails.other_information,
		research_interview_slots: createSurveyStore.surveyDetails.research_interview_slots,
		screener_questions: createSurveyStore.surveyDetails.screener_questions,
		signature_required: createSurveyStore.surveyDetails.signature_required,
		survey_link: createSurveyStore.surveyDetails.survey_link,
		target_audience_sizes: createSurveyStore.surveyDetails.target_audience_sizes,
		target_countries: createSurveyStore.surveyDetails.target_countries,
		target_creator_count: createSurveyStore.surveyDetails.target_creator_count,
	}
	try {
		let response = await axios({
			method: "POST",
			url: baseURL + "api/researcher/research_interview",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: formData
		});
		console.log(response);
		if (response.data) {
			if (response.data.payment_link) {
				window.open(response.data.payment_link, '_self')
				return response.data;
			}
			// await checkoutResearch(response.data.id)
			return response.data;
		}
	} catch (err) {
		console.log("Error updating info", err);
	}
}

export async function updateInterview() {
	let formData = {
		id: createSurveyStore.surveyDetails.id,
		creator_content_formats: createSurveyStore.surveyDetails.creator_content_formats,
		creator_genres: createSurveyStore.surveyDetails.creator_genres,
		creator_incentive_amount: createSurveyStore.surveyDetails.creator_incentive_amount,
		creator_platforms: createSurveyStore.surveyDetails.creator_platforms,
		description: createSurveyStore.surveyDetails.description,
		doc_object_key: createSurveyStore.surveyDetails.doc_object_key,
		duration: createSurveyStore.surveyDetails.duration,
		is_content_format_deal_breaker: createSurveyStore.surveyDetails.is_content_format_deal_breaker,
		is_genre_deal_breaker: createSurveyStore.surveyDetails.is_genre_deal_breaker,
		is_platform_deal_breaker: createSurveyStore.surveyDetails.is_platform_deal_breaker,
		name: createSurveyStore.surveyDetails.name,
		other_information: createSurveyStore.surveyDetails.other_information,
		research_interview_slots: createSurveyStore.surveyDetails.research_interview_slots,
		screener_questions: createSurveyStore.surveyDetails.screener_questions,
		signature_required: createSurveyStore.surveyDetails.signature_required,
		survey_link: createSurveyStore.surveyDetails.survey_link,
		target_audience_sizes: createSurveyStore.surveyDetails.target_audience_sizes,
		target_countries: createSurveyStore.surveyDetails.target_countries,
		target_creator_count: createSurveyStore.surveyDetails.target_creator_count,
	}
	try {
		let response = await axios({
			method: "PUT",
			url: baseURL + "api/researcher/research_interview",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: formData
		});
		console.log(response);
		if (response.data) {

			// if (!createSurveyStore.surveyDetails.payment_complete) {
			// 	await checkoutResearch(response.data.id)
			// }
			if (response.data.payment_link) {
				window.open(response.data.payment_link, '_self')
				return response.data;
			}
			return response.data;
		}
	} catch (err) {
		console.log("Error updating info", err);
	}
}

export async function connectGoogleCalendar() {
	try {
		let response = await axios({
			method: "GET",
			url: baseURL + "api/researcher/get_google_auth_url",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
		});
		console.log(response);
		return response.data;
	} catch (err) {
		console.log("Error updating info", err);
	}
}

export async function getGoogleCalendarEvents(start_date, end_date) {
	console.log(start_date, end_date)
	let query = new URLSearchParams()
	query.append("start_date", start_date.toJSON())
	query.append("end_date", end_date.toJSON())
	try {
		let response = await axios({
			method: "GET",
			url: baseURL + "api/researcher/get_calendar",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			params: query,
		});
		console.log(response);
		if (response.data) {
			return response.data;
		}
	} catch (err) {
		console.log("Error updating info", err);
		return [];
	}
}

export async function subscribeToResearchersPlan(plan) {
	let params = new URLSearchParams()
	params.append("lookup_key", plan)

	try {
		let response = await axios({
			method: "GET",
			url: baseURL + "api/researcher/subscribe",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			params: params,
		});
		console.log(response);
		if (response.data) {
			return response.data;
		}
	} catch (err) {
		console.log("Error updating info", err);
	}
}

export async function getStudiesInfoById(id) {
	try {
		let response = await axios({
			method: "GET",
			url: baseURL + "api/researcher/survey?id=" + id,
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
		});
		console.log(response);
		if (response.data) {
			return response.data;
		}
	} catch (err) {
		console.log("Error updating info", err);
	}
}

export async function checkoutResearch(id) {
	try {
		let response = await axios({
			method: "GET",
			url: baseURL + "api/researcher/checkout_research?id=" + id,
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
		});
		console.log(response);
		if (response.data) {
			window.open(response.data, "_self")
			// return response.data;
		}
	} catch (err) {
		console.log("Error updating info", err);
	}
}

export async function creatorSignUp(name, email, referral_id) {
	let data = {
		email: email,
		name: name
	}
	if (referral_id) {
		data["referral_id"] = referral_id
	}
	try {
		let response = await axios({
			method: "POST",
			url: baseURL + "api/creator/signup",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: data
		});
		console.log(response);
		if (response.data) {
			await creatorLogin(email);
			return response.data;
		}
	} catch (err) {
		console.error("Error in creator signing up", err);
		alert(err.response.data.error)
	}
}
export async function creatorLogin(email) {
	try {
		let response = await axios({
			method: "POST",
			url: baseURL + "api/creator/login",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: { email: email }
		});
		console.log(response);
		if (response.data.message) {
			userStore.$patch({
				user: { email: email },
			});
			return response.data
		}
	} catch (err) {
		console.error("Error in Creator login", err);
		alert("You have not yet registerd. Register now!")
	}
}

export async function verifyCreator(otp, email) {
	let data = {
		email: email,
		code: otp,
	};
	try {
		let response = await axios({
			method: "POST",
			url: baseURL + "api/creator/verify_otp",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: data,
		});
		if (response.data) {
			userStore.saveUserInfo(response.data)
			mixpanel.track('creator_login')
			return response.data
		}
	} catch (err) {
		console.error("Error in Verifying creator login", err);
	}
}
export async function getCreatorInfo() {
	try {
		let response = await axios({
			method: "GET",
			url: baseURL + "api/creator",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
		});
		console.log(response);
		if (response.data) {
			userStore.saveUserInfo(response.data)
			return response.data;
		}
	} catch (err) {
		console.error("Error in getting creator info", err);
	}
}
export async function updateCreatorInfo(formData) {
	let data = {}

	if (formData) {
		data = formData
	} else {
		data = {
			name: creatorOnboardingStore.creatorsDetails.name,
			gender: creatorOnboardingStore.creatorsDetails.gender,
			country: creatorOnboardingStore.creatorsDetails.country[0],
			content_genres: creatorOnboardingStore.creatorsDetails.content_genres,
			platforms: creatorOnboardingStore.creatorsDetails.platforms,
			content_formats: creatorOnboardingStore.creatorsDetails.content_formats,
			dob: new Date(creatorOnboardingStore.creatorsDetails.dob).toISOString().split('T')[0],
		}
	}

	try {
		let response = await axios({
			method: "PUT",
			url: baseURL + "api/creator",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: data,
		});
		if (response.data) {
			return response.data
		}
	} catch (err) {
		console.error("Error in updating creator info", err);
	}
}
export async function uploadUserProfileImage(query) {
	try {
		let response = await axios({
			method: "GET",
			url: baseURL + "api/creator/image?file_name=" + query,
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
		});
		return response.data;
	} catch (err) {
		console.log("Error updating info", err);
	}
}
export async function addProfileImage(formData) {
	try {
		let response = await axios({
			method: "POST",
			url: baseURL + "api/creator/image",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: formData,
		});
		if (response) {
			getCreatorInfo()
		}
	} catch (err) {
		console.log("Error updating info", err);
	}
}
export async function getCreatorHomeStudies() {
	try {
		let response = await axios({
			method: "GET",
			url: baseURL + "api/creator/dashboard",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
		});
		console.log(response);
		if (response.data) {
			return response.data
		}
	} catch (err) {
		console.error("Error in fetching creators dashboard studies", err);
	}
}
export async function applyForResearch(formData, research_id) {
	let form = {
		"research_id": research_id,
	}
	if (formData.length > 0) {
		form["screener_question_answers"] = formData
	}
	try {
		let response = await axios({
			method: "POST",
			url: baseURL + "api/creator/research/apply",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: form
		});
		if (response.data) {
			return response.data
		}
	} catch (err) {
		console.error("Error in error in applying for researches", err);
	}
}
export async function getResearchApplications() {
	upcomingSurveyStore.startLoader()
	try {
		let response = await axios({
			method: "GET",
			url: baseURL + "api/creator/research/applications",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
		});
		console.log(response);
		if (response.data) {
			upcomingSurveyStore.saveUpcomingSurveys(response.data)
			upcomingSurveyStore.stopLoader()
			return response.data
		}
	} catch (err) {
		console.error("Error in fetching research applications", err);
	}
	upcomingSurveyStore.stopLoader()
}
export async function scheduleInterview(data) {
	try {
		let response = await axios({
			method: "POST",
			url: baseURL + "api/creator/research/schedule_interview",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: data
		});
		console.log(response);
		if (response.data) {
			return response.data
		}
	} catch (err) {
		console.error("Error in fetching research applications", err);
	}
}
export async function getSurveyApplicants(research_id) {
	let params = new URLSearchParams()
	params.append('research_id', research_id)
	// params.append('status', "approved")
	try {
		let response = await axios({
			method: "GET",
			url: baseURL + "api/researcher/research_applications",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			params: params,
		});
		console.log(response);
		if (response.data) {
			return response.data
		}
	} catch (err) {
		console.error("Error in fetching research applications", err);
	}
}
export async function logout() {
	try {
		let response = await axios({
			method: "GET",
			url: baseURL + "logout/",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
		});
		console.log(response);
		if (response.data) {
			return response.data
		}
	} catch (err) {
		console.error("Unable to log out", err);
	}
}
export async function acceptRejectRequest(formData) {
	try {
		let response = await axios({
			method: "PUT",
			url: baseURL + "api/researcher/research/application",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: formData,
		});
		if (response.data) {
			return response.data
		}
	} catch (err) {
		console.error("Unable to Change approve/reject request", err);
	}
}
export async function autoApproveResearchApplications(formData) {
	try {
		let response = await axios({
			method: "PUT",
			url: baseURL + "api/researcher/research_interview",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: formData,
		});
		if (response.data) {
			return response.data
		}
	} catch (err) {
		console.error("Unable to update auto approve status", err);
	}
}
export async function markAllApplicationsCompleted(formData) {
	try {
		let response = await axios({
			method: "POST",
			url: baseURL + "api/researcher/research/applications_to_completed",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: formData,
		});
		if (response.data) {
			return response.data
		}
	} catch (err) {
		console.error("Unable to mark all apllictions as complete", err);
	}
}

export async function updatingSurveyStatus(formData) {
	try {
		let response = await axios({
			method: "PUT",
			url: baseURL + "api/researcher/survey",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: formData,
		});
		if (response.data) {
			return response.data
		}
	} catch (err) {
		console.error("Error in updating status", err);
	}
}
export async function connectMeta() {
	try {
		let response = await axios({
			method: "GET",
			url: baseURL + "api/creator/connect/instagram",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
		});
		if (response.data) {
			window.open(response.data.url, '_self')
			return response.data
		}
	} catch (err) {
		console.error("Error in updating status", err);
	}
}
export async function connectTwitch() {
	try {
		let response = await axios({
			method: "GET",
			url: baseURL + "api/creator/connect/twitch",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
		});
		if (response.data) {
			window.open(response.data.url, '_self')
			return response.data
		}
	} catch (err) {
		console.error("Error in updating status", err);
	}
}
export async function connectTikTok() {
	try {
		let response = await axios({
			method: "GET",
			url: baseURL + "api/creator/connect/tiktok",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
		});
		if (response.data) {
			window.open(response.data.url, '_self')
			return response.data
		}
	} catch (err) {
		console.error("Error in updating status", err);
	}
}
export async function connectYouTube() {
	try {
		let response = await axios({
			method: "GET",
			url: baseURL + "api/creator/connect/youtube",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
		});
		if (response.data) {
			window.open(response.data.url, '_self')
			return response.data
		}
	} catch (err) {
		console.error("Error in updating status", err);
	}
}
export async function getCreatorStudyById(id) {
	try {
		let response = await axios({
			method: "GET",
			url: baseURL + "api/creator/research?id=" + id,
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
		});
		if (response.data) {
			return response.data
		}
	} catch (err) {
		alert(err?.response?.data?.error)
		console.error("Error in updating status", err);
	}
}
export async function getOrgnizationsMembers(id) {
	try {
		let response = await axios({
			method: "GET",
			url: baseURL + "api/researcher/organisation/members",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
		});
		if (response.data) {
			return response.data
		}
	} catch (err) {
		console.error("Error in updating status", err);
	}
}

export async function sendNewInvite(data) {
	try {
		let response = await axios({
			method: "POST",
			url: baseURL + "api/researcher/invite",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: data,
		});
		if (response.data) {
			return response.data
		}
	} catch (err) {
		console.error("Error in updating status", err);
	}
}
export async function removeTeamMember(data) {
	try {
		let response = await axios({
			method: "POST",
			url: baseURL + "api/researcher/member/delete",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: data,
		});
		if (response.data) {
			return response.data
		}
	} catch (err) {
		console.error("Error in updating status", err);
	}
}

export async function subscribePayAsGo(data) {
	try {
		let response = await axios({
			method: "POST",
			url: baseURL + "api/researcher/subscribe/pay_as_you_go",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: data,
		});
		if (response.data) {
			return response.data
		}
	} catch (err) {
		console.error("Error in updating status", err);
	}
}
export async function changeSurveyStatus(id, status) {
	let formData = {
		id: id,
		status: status,
	}
	try {
		let response = await axios({
			method: "POST",
			url: baseURL + "api/researcher/research/status",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: formData
		});
		if (response.data) {
			if (response.data.payment_link) {
				window.open(response.data.payment_link, '_self')
				return response.data;
			}
			return response.data;
		}
	} catch (err) {
		console.log("Error updating info", err);
	}
}

export async function subscribeToMaillist(data) {
	try {
		let response = await axios({
			method: "POST",
			url: baseURL + "api/creator/mailing-list",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: data,
		});
		if (response.data) {
			return response.data
		}
	} catch (err) {
		console.error("Error in updating status", err);
	}
}
export async function getEarningsInfo() {
	try {
		let response = await axios({
			method: "GET",
			url: baseURL + "api/creator/ledger",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
		});
		if (response.data) {
			earningsStore.saveEarnings(response.data)
			return response.data
		}
	} catch (err) {
		console.error("Error in updating status", err);
		return []
	}
}
export async function createPayOutInfo(data) {
	try {
		let response = await axios({
			method: "POST",
			url: baseURL + "api/creator/payout-info",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: data,
		});
		if (response.data) {
			await getCreatorInfo()
			return response.data
		}
	} catch (err) {
		console.error("Error in updating status", err);
		alert(JSON.stringify(err?.response?.data?.error))
		return []
	}
}
export async function debitRequest(data) {
	try {
		let response = await axios({
			method: "POST",
			url: baseURL + "api/creator/debit-request",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: data,
		});
		if (response.data) {
			return response.data
		}
	} catch (err) {
		console.error("Error in updating status", err);
		alert(JSON.stringify(err?.response?.data?.error))
		return null
	}
}
export async function getOpportunitiesById(id) {
	try {
		let response = await axios({
			method: "GET",
			url: baseURL + "api/creator/research_dashboard?id=" + id,
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
		});
		if (response.data) {
			return response.data
		} else {
			return null
		}
	} catch (err) {
		console.error("Error in updating status", err);
		// alert(err?.response?.data?.error)
		return err?.response?.data?.error
	}
}
// Admin Apis
export async function getAllCreators(params) {
	try {
		let response = await axios({
			method: "GET",
			url: baseURL + "api/admin/creators",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			params: params
		});
		if (response.data) {
			return response.data
		} else {
			return []
		}
	} catch (err) {
		console.error("Error in updating status", err);
		alert(JSON.stringify(err?.response?.data?.errors))
		return []
	}
}
export async function getAllResearcher(params) {
	try {
		let response = await axios({
			method: "GET",
			url: baseURL + "api/admin/researchers",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			params: params
		});
		if (response.data) {
			return response.data
		} else {
			return []
		}
	} catch (err) {
		console.error("Error in updating status", err);
		alert(JSON.stringify(err?.response?.data?.errors))
		return []
	}
}
export async function getAllResearches(params) {
	try {
		let response = await axios({
			method: "GET",
			url: baseURL + "api/admin/researches",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			params: params
		});
		if (response.data) {
			return response.data
		} else {
			return []
		}
	} catch (err) {
		console.error("Error in updating status", err);
		alert(JSON.stringify(err?.response?.data?.errors))
		return []
	}
}
export async function getAllDebitRequest() {
	try {
		let response = await axios({
			method: "GET",
			url: baseURL + "api/admin/debit-requests",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
		});
		if (response.data) {
			return response.data
		} else {
			return []
		}
	} catch (err) {
		console.error("Error in updating status", err);
		alert(JSON.stringify(err?.response?.data?.errors))
		return []
	}
}
export async function createCreator(formData) {
	console.log("creator", formData);
	try {
		let response = await axios({
			method: "POST",
			url: baseURL + "api/admin/creator",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: formData
		});
		if (response.data) {
			return response.data
		}
	} catch (err) {
		console.error("Error in updating status", err);
		alert(JSON.stringify(err?.response?.data?.errors))
		return {}
	}
}
export async function editCreator(formData) {
	console.log("creator", formData);
	try {
		let response = await axios({
			method: "PUT",
			url: baseURL + "api/admin/creator",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: formData
		});
		if (response.data) {
			return response.data
		}
	} catch (err) {
		console.error("Error in updating status", err);
		alert(JSON.stringify(err?.response?.data?.errors))
		return {}
	}
}
export async function acceptWithdrawRequest(formData) {
	try {
		let response = await axios({
			method: "PUT",
			url: baseURL + "api/admin/debit-request",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: formData
		});
		if (response.data) {
			return response.data
		}
	} catch (err) {
		console.error("Error in updating status", err);
		alert(JSON.stringify(err?.response?.data?.errors))
		return {}
	}
}

export async function applicantsForAdmin(research_id) {
	let params = new URLSearchParams()
	params.append('research_id', research_id)
	try {
		let response = await axios({
			method: "GET",
			url: baseURL + "api/admin/research/applicants",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			params: params,
		});
		if (response.data) {
			return response.data
		}
	} catch (err) {
		console.error("Error in updating status", err);
		alert(JSON.stringify(err?.response?.data?.errors))
		return false
	}
}

export async function statusUpdateApplicationAdmin(formDate) {
	try {
		let response = await axios({
			method: "POST",
			url: baseURL + "api/admin/research/status",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: formDate,
		});
		if (response.data) {
			return response.data
		}
	} catch (err) {
		console.error("Error in updating status", err);
		alert(JSON.stringify(err?.response?.data?.errors))
		return false
	}
}
export async function markAllApplicationsCompletedAdmin(formData) {
	try {
		let response = await axios({
			method: "POST",
			url: baseURL + "api/admin/researcher/research/applications_to_completed",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: formData,
		});
		if (response.data) {
			return response.data
		}
	} catch (err) {
		console.error("Unable to mark all apllictions as complete", err);
	}
}
export async function getMailingListInfo() {
	try {
		let response = await axios({
			method: "GET",
			url: baseURL + "api/admin/mailing-list",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
		});
		if (response.data) {
			return response.data
		} else {
			return []
		}
	} catch (err) {
		console.error("Error in updating status", err);
		alert(JSON.stringify(err?.response?.data?.errors))
		return false
	}
}
export async function autoApproveResearchApplicationsAdmin(formData) {
	try {
		let response = await axios({
			method: "PUT",
			url: baseURL + "api/admin/research_interview",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: formData,
		});
		if (response.data) {
			return response.data
		}
	} catch (err) {
		console.error("Unable to update auto approve status", err);
		alert(JSON.stringify(err?.response?.data?.errors))
		return false
	}
}

export async function acceptRejectRequestAdmin(formData) {
	try {
		let response = await axios({
			method: "PUT",
			url: baseURL + "api/admin/research/applicant",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: formData,
		});
		if (response.data) {
			return response.data
		}
	} catch (err) {
		console.error("Unable to Change approve/reject request", err);
	}
}
export async function getStudiesInfoByIdAdmin(id) {
	try {
		let response = await axios({
			method: "GET",
			url: baseURL + "api/admin/research?id=" + id,
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
		});
		if (response.data) {
			return response.data;
		}
	} catch (err) {
		console.log("Error updating info", err);
	}
}
export async function getAdminDashboardInfo() {
	try {
		let response = await axios({
			method: "GET",
			url: baseURL + "api/admin/dashboard",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
		});
		if (response.data) {
			return response.data;
		}
	} catch (err) {
		console.log("Error updating info", err);
	}
}
export async function getAllOrgnisationsAdmin() {
	try {
		let response = await axios({
			method: "GET",
			url: baseURL + "api/admin/organisations",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
		});
		if (response.data) {
			return response.data;
		} else {
			return []
		}
	} catch (err) {
		console.log("Error updating info", err);
		return []
	}
}
export async function getOrgnisationMembers(id) {
	try {
		let response = await axios({
			method: "GET",
			url: baseURL + "api/admin/organisation_members?id=" + id,
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
		});
		if (response.data) {
			return response.data;
		} else {
			return {}
		}
	} catch (err) {
		console.log("Error updating info", err);
		return {}
	}
}
export async function createInterviewAdmin() {
	let formData = {
		creator_content_formats: createSurveyStore.surveyDetails.creator_content_formats,
		creator_genres: createSurveyStore.surveyDetails.creator_genres,
		creator_incentive_amount: createSurveyStore.surveyDetails.creator_incentive_amount,
		creator_platforms: createSurveyStore.surveyDetails.creator_platforms,
		description: createSurveyStore.surveyDetails.description,
		doc_object_key: createSurveyStore.surveyDetails.doc_object_key,
		duration: createSurveyStore.surveyDetails.duration,
		is_content_format_deal_breaker: createSurveyStore.surveyDetails.is_content_format_deal_breaker,
		is_genre_deal_breaker: createSurveyStore.surveyDetails.is_genre_deal_breaker,
		is_platform_deal_breaker: createSurveyStore.surveyDetails.is_platform_deal_breaker,
		name: createSurveyStore.surveyDetails.name,
		other_information: createSurveyStore.surveyDetails.other_information,
		research_interview_slots: createSurveyStore.surveyDetails.research_interview_slots,
		screener_questions: createSurveyStore.surveyDetails.screener_questions,
		signature_required: createSurveyStore.surveyDetails.signature_required,
		survey_link: createSurveyStore.surveyDetails.survey_link,
		target_audience_sizes: createSurveyStore.surveyDetails.target_audience_sizes,
		target_countries: createSurveyStore.surveyDetails.target_countries,
		target_creator_count: createSurveyStore.surveyDetails.target_creator_count,
		researcher_id: createSurveyStore.surveyDetails.researcher_id,
		organisation_id: createSurveyStore.surveyDetails.organisation_id,
	}
	try {
		let response = await axios({
			method: "POST",
			url: baseURL + "api/admin/research_interview",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: formData,
		});
		if (response.data) {
			return response.data;
		} else {
			return {}
		}
	} catch (err) {
		console.log("Error updating info", err);
		alert(JSON.stringify(err?.response?.data?.errors))
		return false

	}
}

export async function createSurveyAdmin() {
	let formData = {
		creator_content_formats: createSurveyStore.surveyDetails.creator_content_formats,
		creator_genres: createSurveyStore.surveyDetails.creator_genres,
		creator_incentive_amount: createSurveyStore.surveyDetails.creator_incentive_amount,
		creator_platforms: createSurveyStore.surveyDetails.creator_platforms,
		deadline: createSurveyStore.surveyDetails.deadline,
		description: createSurveyStore.surveyDetails.description,
		doc_object_key: createSurveyStore.surveyDetails.doc_object_key,
		duration: createSurveyStore.surveyDetails.duration,
		is_content_format_deal_breaker: createSurveyStore.surveyDetails.is_content_format_deal_breaker,
		is_genre_deal_breaker: createSurveyStore.surveyDetails.is_genre_deal_breaker,
		is_platform_deal_breaker: createSurveyStore.surveyDetails.is_platform_deal_breaker,
		name: createSurveyStore.surveyDetails.name,
		other_information: createSurveyStore.surveyDetails.other_information,
		screener_questions: createSurveyStore.surveyDetails.screener_questions,
		signature_required: createSurveyStore.surveyDetails.signature_required,
		survey_link: createSurveyStore.surveyDetails.survey_link,
		target_audience_sizes: createSurveyStore.surveyDetails.target_audience_sizes,
		target_countries: createSurveyStore.surveyDetails.target_countries,
		target_creator_count: createSurveyStore.surveyDetails.target_creator_count,
		researcher_id: createSurveyStore.surveyDetails.researcher_id,
		organisation_id: createSurveyStore.surveyDetails.organisation_id,
		is_ipsos: createSurveyStore.surveyDetails.is_ipsos,
	}
	if (createSurveyStore.surveyDetails?.is_ipsos) {
		formData['ipsos_id'] = createSurveyStore.surveyDetails.ipsos_id
	}
	try {
		let response = await axios({
			method: "POST",
			url: baseURL + "api/admin/survey",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: formData
		});
		console.log(response);
		if (response.data) {
			if (response.data.payment_link) {
				window.open(response.data.payment_link, '_self')
				return response.data;
			}
			return response.data;
		}
	} catch (err) {
		console.log("Error updating info", err);
		alert(JSON.stringify(err?.response?.data?.errors))
		return false
	}
}
export async function updateInterviewAdmin() {
	let formData = {
		id: createSurveyStore.surveyDetails.id,
		creator_content_formats: createSurveyStore.surveyDetails.creator_content_formats,
		creator_genres: createSurveyStore.surveyDetails.creator_genres,
		creator_incentive_amount: createSurveyStore.surveyDetails.creator_incentive_amount,
		creator_platforms: createSurveyStore.surveyDetails.creator_platforms,
		description: createSurveyStore.surveyDetails.description,
		doc_object_key: createSurveyStore.surveyDetails.doc_object_key,
		duration: createSurveyStore.surveyDetails.duration,
		is_content_format_deal_breaker: createSurveyStore.surveyDetails.is_content_format_deal_breaker,
		is_genre_deal_breaker: createSurveyStore.surveyDetails.is_genre_deal_breaker,
		is_platform_deal_breaker: createSurveyStore.surveyDetails.is_platform_deal_breaker,
		name: createSurveyStore.surveyDetails.name,
		other_information: createSurveyStore.surveyDetails.other_information,
		research_interview_slots: createSurveyStore.surveyDetails.research_interview_slots,
		screener_questions: createSurveyStore.surveyDetails.screener_questions,
		signature_required: createSurveyStore.surveyDetails.signature_required,
		survey_link: createSurveyStore.surveyDetails.survey_link,
		target_audience_sizes: createSurveyStore.surveyDetails.target_audience_sizes,
		target_countries: createSurveyStore.surveyDetails.target_countries,
		target_creator_count: createSurveyStore.surveyDetails.target_creator_count,
		researcher_id: createSurveyStore.surveyDetails.researcher_id,
		// organisation_id: createSurveyStore.surveyDetails.organisation_id,
	}
	try {
		let response = await axios({
			method: "PUT",
			url: baseURL + "api/admin/research_interview",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: formData,
		});
		if (response.data) {
			return response.data;
		} else {
			return {}
		}
	} catch (err) {
		console.log("Error updating info", err);
		alert(JSON.stringify(err?.response?.data?.errors))
		return false
	}
}

export async function updateSurveyAdmin() {
	let formData = {
		id: createSurveyStore.surveyDetails.id,
		creator_content_formats: createSurveyStore.surveyDetails.creator_content_formats,
		creator_genres: createSurveyStore.surveyDetails.creator_genres,
		creator_incentive_amount: createSurveyStore.surveyDetails.creator_incentive_amount,
		creator_platforms: createSurveyStore.surveyDetails.creator_platforms,
		deadline: createSurveyStore.surveyDetails.deadline,
		description: createSurveyStore.surveyDetails.description,
		doc_object_key: createSurveyStore.surveyDetails.doc_object_key,
		duration: createSurveyStore.surveyDetails.duration,
		is_content_format_deal_breaker: createSurveyStore.surveyDetails.is_content_format_deal_breaker,
		is_genre_deal_breaker: createSurveyStore.surveyDetails.is_genre_deal_breaker,
		is_platform_deal_breaker: createSurveyStore.surveyDetails.is_platform_deal_breaker,
		name: createSurveyStore.surveyDetails.name,
		other_information: createSurveyStore.surveyDetails.other_information,
		screener_questions: createSurveyStore.surveyDetails.screener_questions,
		signature_required: createSurveyStore.surveyDetails.signature_required,
		survey_link: createSurveyStore.surveyDetails.survey_link,
		target_audience_sizes: createSurveyStore.surveyDetails.target_audience_sizes,
		target_countries: createSurveyStore.surveyDetails.target_countries,
		target_creator_count: createSurveyStore.surveyDetails.target_creator_count,
		researcher_id: createSurveyStore.surveyDetails.researcher_id,
		// organisation_id: createSurveyStore.surveyDetails.organisation_id,
		is_ipsos: createSurveyStore.surveyDetails.is_ipsos,
	}
	if (createSurveyStore.surveyDetails?.is_ipsos) {
		formData['ipsos_id'] = createSurveyStore.surveyDetails.ipsos_id
	}

	try {
		let response = await axios({
			method: "PUT",
			url: baseURL + "api/admin/survey",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: formData
		});
		console.log(response);
		if (response.data) {
			if (response.data.payment_link) {
				window.open(response.data.payment_link, '_self')
				return response.data;
			}
			return response.data;
		}
	} catch (err) {
		console.log("Error updating info", err);
		alert(JSON.stringify(err?.response?.data?.errors))
		return false
	}
}

export async function createOrganisation(formData) {
	try {
		let response = await axios({
			method: "POST",
			url: baseURL + "api/admin/organisation",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: formData,
		});
		if (response.data) {
			return response.data;
		} else {
			return
		}
	} catch (err) {
		console.log("Error updating info", err);
		return
	}
}
export async function editOrganisation(formData) {
	try {
		let response = await axios({
			method: "PUT",
			url: baseURL + "api/admin/organisation",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: formData,
		});
		if (response.data) {
			return response.data;
		} else {
			return
		}
	} catch (err) {
		console.log("Error updating info", err);
		return
	}
}

export async function createResearcher(formData) {
	try {
		let response = await axios({
			method: "POST",
			url: baseURL + "api/admin/researcher",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: formData,
		});
		if (response.data) {
			return response.data;
		} else {
			return {}
		}
	} catch (err) {
		console.log("Error updating info", err);
		return {}
	}
}
export async function editResearcher(formData) {
	try {
		let response = await axios({
			method: "PUT",
			url: baseURL + "api/admin/researcher",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: formData,
		});
		if (response.data) {
			return response.data;
		} else {
			return {}
		}
	} catch (err) {
		console.log("Error updating info", err);
		return {}
	}
}

export async function getInstaImage(formData) {
	try {
		let response = await axios({
			method: "POST",
			url: baseURL + "api/admin/instagram_details",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: formData,
		});
		if (response.data) {
			return response.data;
		} else {
			return null
		}
	} catch (err) {
		console.log("Error updating info", err);
		return null
	}
}
export async function updateInstaCookie(formData) {
	try {
		let response = await axios({
			method: "PUT",
			url: baseURL + "api/admin/instagram_cookie",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: formData,
		});
		if (response.data) {
			alert("Cookie Updated")
			return response.data;
		} else {
			return
		}
	} catch (err) {
		console.log("Error updating info", err);
	}
}
export async function getInstaCookie() {
	try {
		let response = await axios({
			method: "GET",
			url: baseURL + "api/admin/instagram_cookie",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
		});
		if (response.data) {
			return response.data;
		} else {
			return
		}
	} catch (err) {
		console.log("Error updating info", err);
	}
}
export async function getReferrerdCreator(creator_id) {
	let params = new URLSearchParams()
	params.append("creator_id", creator_id)
	try {
		let response = await axios({
			method: "GET",
			url: baseURL + "api/admin/referrals",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			params: params,
		});
		if (response.data) {
			return response.data;
		} else {
			return []
		}
	} catch (err) {
		console.log("Error updating info", err);
		return []
	}
}

export async function sendBulkEmails(formdata) {
	try {
		let response = await axios({
			method: "POST",
			url: baseURL + "api/admin/send_email",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: formdata
		});

		return response;

	} catch (err) {
		console.log("Error updating info", err);
		return err
	}
}

export async function sendReferralsEmails() {
	try {
		let response = await axios({
			method: "GET",
			url: baseURL + "api/admin/email/referrals",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
		});
		return response;

	} catch (err) {
		return err
	}
}
export async function sendConnectSocialEmails() {
	try {
		let response = await axios({
			method: "GET",
			url: baseURL + "api/admin/email/connect_social",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
		});
		return response;

	} catch (err) {
		return err
	}
}

export async function createDuplicateStudy(id) {
	try {
		let response = await axios({
			method: "POST",
			url: baseURL + "api/admin/research/duplicate?id=" + id,
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
		});
		return response;

	} catch (err) {
		// return err
	}
}

export async function creatorsWithBalance() {
	try {
		let response = await axios({
			method: "GET",
			url: baseURL + "api/admin/creators/balance?page=1",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
		});
		return response.data;

	} catch (err) {
		return err
	}
}

export async function markCreatorAsPaid(formData) {
	try {
		let response = await axios({
			method: "POST",
			url: baseURL + "api/admin/creators/balance",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: formData
		});
		return response.data;

	} catch (err) {
		// return err
	}
}

export async function deleteApplicant(id) {
	try {
		let response = await axios({
			method: "DELETE",
			url: baseURL + "api/admin/research/applicant?id=" + id,
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
		});
		return response.data;

	} catch (err) {
		// return err
	}
}

export async function saveInstagramUsername(formData) {


	try {
		let response = await axios({
			method: "POST",
			url: baseURL + "api/creator/connect/instagram",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: formData
		});
		return response.data;

	} catch (err) {
		// return err
	}
}

export async function createAdAdmin(adInfo) {
	let formData = {
		"title": adInfo.title,
		"description": adInfo.description,
		"link": adInfo.link,
		"button_text": adInfo.button_text
	}
	try {
		let response = await axios({
			method: "POST",
			url: baseURL + "api/admin/ad",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: formData
		});
		return response;

	} catch (err) {
		return err
	}
}
export async function editAdAdmin(adInfo) {
	let formData = {
		"title": adInfo.title,
		"description": adInfo.description,
		"link": adInfo.link,
		"button_text": adInfo.button_text
	}
	try {
		let response = await axios({
			method: "PUT",
			url: baseURL + "api/admin/ad/" + adInfo.id,
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: formData
		});
		return response;

	} catch (err) {
		console.log(err);

		return err

	}
}


export async function getAdsAdmin() {
	try {
		let response = await axios({
			method: "GET",
			url: baseURL + "api/admin/ads",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
		});
		return response.data;

	} catch (err) {
		// return err
	}
}

export async function changeAdStatusAdmin(adInfo) {
	let formData = {
		"status": adInfo.status
	}
	try {
		let response = await axios({
			method: "PUT",
			url: baseURL + "api/admin/ad/" + adInfo.id,
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
			data: formData
		});
		return response.data;

	} catch (err) {
		console.log(err);

		return err

	}
}

export async function getAds() {
	try {
		let response = await axios({
			method: "GET",
			url: baseURL + "api/creator/ads",
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
		});
		return response.data || [];

	} catch (err) {
		return []
	}
}